import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = [ "name", "pivot" ]
    connect() {
    }

    save() {
        const self = this;

        webix.ready(function() {
            const name = self.nameTarget.value;
            const structure = $$("pivot").getStructure();
            const mode = $$("pivot").getState().mode;
            const chart = $$("pivot").getState().chart;
            const url = self.element.dataset.patchUrl;

            let data = new FormData()
            data.append("report_template_view[name]", name)
            data.append("report_template_view[mode]", mode)
            data.append("report_template_view[chart]", JSON.stringify(chart))
            data.append("report_template_view[structure]", JSON.stringify(structure))

            Rails.ajax({
                url: url,
                type: 'PATCH',
                data: data,
                success: function(response, status) {
                    // redirect
                }
            })
        })
    }
}
