import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
// Fullcalendar
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import adaptivePlugin from '@fullcalendar/adaptive';
import frLocale from '@fullcalendar/core/locales/fr';

export default class extends Controller {
  static targets = ['widget'];

  connect() {
    let calendarEl = this.widgetTarget;
    if (calendarEl.dataset.view === 'timeline') {
      this.initializeTimeline();
    } else {
      this.initializeCalendar();
    }
  }

  createCalendarEvent(self, data) {
    const currentUrl = new URL(window.location.href);
    const userId = currentUrl.searchParams.get('q[participations_person_id_eq]');
    const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
    const locale = currentUrl.searchParams.get('locale');
    console.dir(userId, 'userId');
    console.dir(accountId, 'accountId');

    if (!accountId) {
      return;
    }

    // startStr and endStr are returned by drag select
    // dateStr is returned by click
    const startDate = data.startStr || data.dateStr;
    const endDate = data.endStr;

    currentUrl.pathname = `/${accountId}/comments/new`;
    currentUrl.searchParams.set('intent', 'event');
    currentUrl.searchParams.set('locale', locale);

    currentUrl.searchParams.set('start_date', startDate);
    if (endDate) {
      currentUrl.searchParams.set('end_date', endDate);
    }

    window.location.href = currentUrl.toString();
  }

  updateCalendarEvent(calendar, data) {
    let patchUrl = `/events/${data.event.id}`;
    const formData = new FormData();
    formData.append('start_at', data.event.start.toISOString());
    formData.append('end_at', data.event.end.toISOString());
    Rails.ajax({
      type: 'patch',
      dataType: 'json',
      url: patchUrl,
      data: formData,
    });
  }

  initializeCalendar() {
    let calendarEl = this.widgetTarget;
    let eventsPath = calendarEl.dataset.eventsPath || '/events.json';
    let calendarLicense = calendarEl.dataset.license || '';
    const self = this;
    self.calendar = new Calendar(calendarEl, {
      schedulerLicenseKey: calendarLicense,
      plugins: [adaptivePlugin, interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: 'timeGridWeek',
      initialDate: calendarEl.dataset.from || Date.now(),
      editable: false,
      allDaySlot: true,
      scrollTime: '07:00:00',
      firstDay: 1,
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
        startTime: '8:00', // a start time (10am in this example)
        endTime: '18:00', // an end time (6pm in this example)
      },
      locale: frLocale,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek',
      },
      events: eventsPath,
      dateClick: function (data) {
        self.createCalendarEvent(self, data);
      },
      selectable: true,
      select: function (data) {
        self.createCalendarEvent(self, data);
      },
      eventClassNames: function (arg) {
        return `comment_${arg.event._def.publicId}`;
      },
      eventClick: function (info) {
        $('#event-modal')
          .modal('show')
          .find('.modal-content')
          .load('/events/' + info.event.id);
      },
      eventDrop: function (data) {
        self.updateCalendarEvent(self, data);
      },
    });
    this.calendar.render();
  }

  initializeTimeline() {
    let calendarEl = this.widgetTarget;
    let eventsPath = calendarEl.dataset.eventsPath || '/loanable_resource_events.json';
    let resourcesPath = calendarEl.dataset.resourcesPath || '/loanable_resources.json';
    let resourcesLabel = calendarEl.dataset.resourcesLabel || '';
    let calendarLicense = calendarEl.dataset.license || '';
    const self = this;
    self.calendar = new Calendar(calendarEl, {
      schedulerLicenseKey: calendarLicense,
      plugins: [resourceTimelinePlugin],
      initialView: 'resourceTimeline',
      initialDate: calendarEl.dataset.from || Date.now(),
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
      },
      editable: true,
      scrollTime: '07:00:00',
      firstDay: 1,
      nowIndicator: true,
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
        startTime: '8:00', // a start time (10am in this example)
        endTime: '18:00', // an end time (6pm in this example)
      },
      locale: frLocale,
      // headerToolbar: {
      //     left: 'prev,next today',
      //     center: 'title',
      //     right: 'timelineDay, timelineWeek, timelineMonth'
      // },
      events: eventsPath,
      resourceAreaHeaderContent: resourcesLabel,
      resourceGroupField: 'category',
      resources: resourcesPath,
      eventClick: function (info) {
        $('#event-modal')
          .modal('show')
          .find('.modal-content')
          .load('/events/' + info.event.id);
      },
    });
    this.calendar.render();
  }
}
