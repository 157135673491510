import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "quantity", "error", "success" ]
  static values = {
    kind: String,
    stockItemKind: String,
  }

  checkKind() {
    if (this.kindValue !== "unknown" && this.kindValue !== this.stockItemKindValue) {
      this.errorTarget.classList.remove("d-none")
    } else {
      this.errorTarget.classList.add("d-none")
      this.checkQuantity()
    }
  }

  checkQuantity() {
    let quantity = parseInt(this.quantityTarget.value, 10)
    if (quantity > 0) {
      this.errorTarget.classList.add("d-none")
      this.successTarget.classList.remove("d-none")
    } else {
      this.errorTarget.classList.remove("d-none")
      this.successTarget.classList.add("d-none")
    }
  }
}
