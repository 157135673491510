import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "first", "second" ]

    initialize() {
        this.ignoreDisableForm = this.element.getAttribute("data-ignore-disable-form") || "false"
    }

    toggleItem(event) {
        event.preventDefault();
        if (this.secondTarget.style.display == "none") {
            this.secondTarget.style.display = "block";
            this.toggleFormFields(this.secondTarget, false);
            if (this.hasFirstTarget) {
                this.firstTarget.style.display = "none";
                this.toggleFormFields(this.firstTarget, true);
            }
        } else {
            this.secondTarget.style.display = "none";
            this.toggleFormFields(this.secondTarget, true);
            if (this.hasFirstTarget) {
                this.firstTarget.style.display = "block";
                this.toggleFormFields(this.firstTarget, false);
            }
        }
    }

    toggleFormFields(container, val) {
        if (this.ignoreDisableForm != "true") {
            container.querySelectorAll('input, select, button, textarea').forEach(elem => elem.disabled = val);
        }
    }
}
