import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [ "body", "quantityField", "row", "submitBtn" ]

    handleEditLink(event) {
        event.preventDefault()
        this.rowTargets.forEach((el, i) => {
            this.toggleDisplay(el);
        })
    }

    toggleDisplay(el) {
        if (el.style.display === "none") {
            el.style.display = "";
            this.bodyTarget.style.background = "#fbf2d4";
        } else {
            el.style.display = "none";
            this.bodyTarget.style.background = "none";
        }
    }
}
