import { Controller } from "stimulus"
import Rails from '@rails/ujs';
import TomSelect from "tom-select";

let myModal = null;

export default class extends Controller {
    static values = { url: String }
    static targets = [ 'personField' ];

    connect() {
        if (this.hasPersonFieldTarget) {
            this.initTomSelect()
        }
    }

    disconnect() {
        if (this.select) {
            this.select.destroy()
        }
    }

    initTomSelect() {
        let url = this.urlValue
        this.select = new TomSelect(this.personFieldTarget, {
            plugins: ['remove_button'],
            valueField: 'id',
            labelField: 'name',
            searchField: ['first_name', 'last_name', 'email'],
            maxItems: 1,
            selectOnTab: true,
            closeAfterSelect: true,
            hidePlaceholder: false,
            preload: false,
            openOnFocus: true,
            highlight: true,
            load: (query, callback) => {
                var searchUrl = url + '?q=' + encodeURIComponent(query);
                fetch(searchUrl)
                    .then(response => response.json())
                    .then(json => {
                        callback(json.results);
                    }).catch(() => {
                        callback();
                    });
            },
            create: function(input) {
                $('#person-modal .modal').modal("show");
                const first_name = document.getElementById('person_first_name');
                const last_name = document.getElementById('person_last_name');
                first_name.value = input.split(" ")[0] || "";
                last_name.value = input.split(" ")[1] || "";
                return { value: input, text: input }
            }
            ,
            render: {
                option: function (data, escape) {
                    return '<div>' +
                        '<div class="">' + escape(data.name) + '</div>' +
                        '<div class="text-muted"><small>' + escape(data.email) + '<small></div>' +
                        '</div>';
                },
                option_create: function( data, escape ){
                    return `<div class="create">${I18n.add} <strong>${escape(data.input)}</strong>&hellip;</div>`;
                },
                no_results: function(data, escape){
                    return `<div class="no-results">${I18n.no_result}</div>`;
                },
            }
        });

    }
}
