import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "title", "body", "senderEmail", "form", "message"]

    connect() {
        this.hideAll();
    }

    submitForm() {
        if (this.title.value.length > 0) {
            this.hideMessage();
            this.postJson();
        } else {
            this.showMessage(this.element.dataset.localeMessageInvalid);
        }
    }

    hideAll() {
        this.hideMessage();
        this.element.style.display = "none";
    }

    showAll() {
        this.element.style.display = "block";
    }

    clearForm() {
        this.title.value = "";
        this.body.value = "";
    }

    hideForm() {
        this.form.style.display = "none";
    }

    showForm() {
        this.form.style.display = "block";
    }

    hideMessage() {
        this.message.style.display = "none";
    }

    showMessage(message) {
        this.message.innerHTML = message;
        this.message.style.display = "block";
    }

    postJson() {
        self = this;
        fetch("/api/v1/incoming/json", {
            method: "post",
            body: JSON.stringify({title: this.title.value, body: this.body.value, sender_email: this.senderEmail.value, account_slug: "toucan"}),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin'
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            self.hideForm();
            self.clearForm();
            self.showMessage(self.element.dataset.localeMessageSuccess);
        });
    }

    get form() {
        return this.formTarget
    }

    get message() {
        return this.messageTarget
    }

    get title() {
        return this.titleTarget
    }

    get body() {
        return this.bodyTarget
    }

    get senderEmail() {
        return this.senderEmailTarget
    }
}
