import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "file", "error", "button" ]

  checkFormat(event) {
    event.preventDefault()
    const message = this.buttonTarget.dataset["disableMessage"]
    if (this.fileTarget.value.endsWith(".xls") || this.fileTarget.value.endsWith(".xlsx")) {
      this.buttonTarget.textContent = message
      this.buttonTarget.disabled = true
      this.formTarget.submit()
    } else {
      this.errorTarget.classList.remove("d-none")
    }
  }

  toggleError() {
    this.errorTarget.classList.add("d-none")
  }
}
