import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = ["todo"]

    toggle(event) {
        const url = event.target.dataset.patchUrl;
        event.target.parentElement.classList.toggle("todo-completed");

        Rails.ajax({
            url: url,
            type: 'PATCH',
            data: {},
        })
    }

}
