import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "icon" ]

    connect() {
        this.updateIcon();
    }

    updateIcon() {
        const selectedStatus = this.element.querySelector("select").value;
        const iconClass = this.getIconClass(selectedStatus);
        this.iconTarget.innerHTML = `<i class="fa-solid fa-lg ${iconClass}"></i>`;
    }

    getIconClass(status) {
        switch (status) {
            case 'unknown':
                return 'fa-circle-question text-secondary';
            case 'attended':
                return 'fa-circle-check text-success';
            case 'absent':
                return 'fa-circle-xmark text-danger';
            case 'attending':
                return 'fa-user-check text-success';
            case 'not_attending':
                return 'fa-user-xmark text-danger';
            case 'tentatively_attending':
                return 'fa-user-clock text-secondary';
            default:
                return '';
        }
    }
}
