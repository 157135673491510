import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["splittableQuantity", "splittableItemUnit", "splittable"]

    handleSplittable(event) {
        const target = event.target;
        if (target.value == "true") {
            this.splittableQuantityTarget.style.display = "block";
            this.splittableItemUnitTarget.style.display = "block";

        } else {
            this.splittableQuantityTarget.style.display = "none";
            this.splittableItemUnitTarget.style.display = "none";
        }
    }
}
