import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [ "quantity", "unit", "name", "country", "region",  "replacedItemVariantId", "noticeCreate", "noticeReplace" ]

    handleSelectChange(event) {
        const variantId = event.target.value;
        const basePath = event.target.dataset.basePath;
        const resourcePath = [basePath, variantId].join("/");
        this.fetchItemVariantAndUpdateDisplay(resourcePath);
    }

    handleCheckboxChange(event) {
        const targetId = event.target.id;
        if (event.target.checked) {
            if (targetId == "variant_action_keep") {
                this.noticeCreateTarget.style.display = "none"; // hide warning notice
                this.noticeReplaceTarget.style.display = "none"; // hide warning notice
                this.toggleFormFields(true, targetId); // disable all fields
            } else {
                if (targetId == "variant_action_create") {
                    this.noticeReplaceTarget.style.display = "none"; // hide warning notice
                    this.noticeCreateTarget.style.display = "block"; // display warning notice
                }
                if (targetId == "variant_action_replace") {
                    this.noticeCreateTarget.style.display = "none"; // hide warning notice
                    this.noticeReplaceTarget.style.display = "block"; // display warning notice
                }
                this.toggleFormFields(false, targetId); //enable all fields
            }
        } else {
            this.noticeCreateTarget.style.display = "none"; // hide warning notice
            this.noticeReplaceTarget.style.display = "none"; // hide warning notice
            this.toggleFormFields(true, targetId); // disable all fields
        }
    }

    toggleFormFields(value, targetId) {
        // enable or disable all item_variant related form fields
        this.quantityTarget.disabled = value;
        this.unitTarget.disabled = value;
        this.nameTarget.disabled = value;
        this.countryTarget.disabled = value;
        this.regionTarget.disabled = value;
        if (targetId == "variant_action_replace") {
            this.replacedItemVariantIdTarget.disabled = value;
        } else {
            this.replacedItemVariantIdTarget.disabled = true;
        }
    }

    updateFormFieldValues(object) {
        this.quantityTarget.value = object.quantity;
        this.unitTarget.value = object.item_unit_id;
        this.nameTarget.value = object.name;
        this.countryTarget.value = object.country_code;
        this.regionTarget.value = object.region_code;
        this.replacedItemVariantIdTarget.value = object.id;
    }

    fetchItemVariantAndUpdateDisplay(resource) {
        const self = this;
        Rails.ajax({
            type: "get",
            dataType: 'json',
            url: resource,
            success: function(response, status) {
                self.updateFormFieldValues(response)
            },
            error: function(response, status) {
                const messages = response.messages;
                if (messages) {
                    alert(messages.join(", "));
                } else {
                    console.dir(response);
                }
            }
        })
    }
}
