import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [ "parentLineItemField", "priceField", "quantityField", "locationField", "preferedCollectionMethodField", "submitBtn", "row" ]

    connect() {
    }

    handleFieldChange(event) {
        // const el = event.target;
        // console.log(el.name);
        // console.log(el.value);
        // this.submitBtnTarget.classList.remove("disabled");
    }

    handleSave(event) {
        event.preventDefault()
        const formData = new FormData();
        if (this.hasParentLineItemFieldTarget) {
            formData.append("parent_line_item_id", this.parentLineItemFieldTarget.value);
        }
        if (this.hasQuantityFieldTarget) {
            formData.append("line_item[purchased_quantity]", this.quantityFieldTarget.value);
        }
        if (this.hasPriceFieldTarget) {
            formData.append("line_item[price]", this.priceFieldTarget.value);
        }
        if (this.hasLocationFieldTarget) {
            formData.append("line_item[location_id]", this.locationFieldTarget.value);
        }
        if (this.hasPreferedCollectionMethodFieldTarget) {
            formData.append("line_item[prefered_collection_method]", this.preferedCollectionMethodFieldTarget.value);
        }
        const self = this;
        Rails.ajax({
            type: this.data.get('postMethod') || "patch",
            dataType: 'json',
            url: this.data.get('postUrl'),
            data: formData,
            success: function(response, status) {
                const parentEl = self.findParentTableBody(self.rowTarget);
                const $quantitySummary = $(parentEl).find(".quantity-summary")
                const $checkBox = $(self.rowTarget).find(".checkAllChild")

                if (self.hasRowTarget) {
                    self.rowTarget.classList.add("bg-highlighted");
                }
                if (response.parent_status == "pending") {
                    if ($quantitySummary) {
                        $quantitySummary.addClass("bg-danger");
                    }

                    if ($checkBox) {
                        $checkBox.prop( "disabled", true );
                        $checkBox.prop( "checked", false );
                    }
                } else {
                    if ($quantitySummary) {
                        $quantitySummary.removeClass("bg-danger");
                    }

                    if ($checkBox) {
                        $checkBox.prop( "disabled", false );
                        $checkBox.prop( "checked", true );
                    }
                }
            },
            error: function(response, status) {
                const messages = response.messages;
                alert(messages.join(", "));
            }
        })
    }

    findParentTableBody(el) {
        while (el) {
            el = el.parentNode;
            if (el.tagName.toLowerCase() === 'tbody') {
                return el;
            }
        }
        return undefined;
    }
}
