import SlimController from './slim_controller.js';

function getRemoteData(url, callback) {
  fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      // Upon successful fetch send data to callback function.
      // Be sure to send data back in the proper format.
      // Refer to the method setData for examples of proper format.
      callback(json);
    })
    .catch(function (error) {
      // If any errors happened send false back through the callback
      console.dir(error);
      callback([]);
    });
}

export default class extends SlimController {
  afterChange(newVal) {
    const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
    const firstResult = newVal[0];
    // addresses
    const addressElement = document.getElementById('wizard_order_form_address_id');
    // collaboration
    const collaborationElement = document.getElementById('wizard_order_form_destination_contact_gid');

    // Set to empty while fetching
    addressElement.options.length = 0;
    collaborationElement.options.length = 0;

    if (firstResult) {
      const gid = encodeURIComponent(firstResult.value);
      if (accountId) {
        getRemoteData(`/${accountId}/api/v1/subjects/${gid}`, function (data) {
          // Addresses
          const addressOptions = data.addresses.filter((item) => item.related_to_subject === true).map(function (item) {
            return {
              text: item.to_s,
              value: item.id,
              selected: item.main
            };
          });
          addressOptions.forEach(option =>
            addressElement.options.add(new Option(option.text, option.value, true, option.selected))
          );

          // Collaborations
          const collaborationOptions = data.collaborations.map(function (item) {
            return {
              text: item.to_s,
              value: item.gid,
              selected: item.main
            };
          });
          collaborationOptions.forEach(option =>
            collaborationElement.options.add(new Option(option.text, option.value, true, option.selected))
          );
        });
      }
    }
  }
}
