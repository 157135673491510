import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "storage" ]

    checkValue(event) {
        let el = event.currentTarget
        let value = el.options[el.selectedIndex].parentNode.label
        const categories = ["Véhicule", "Vehicle"]

        if (categories.includes(value)) {
          this.storageTarget.classList.remove("d-none")
        } else {
          this.storageTarget.classList.add("d-none")
        }
    }
}
