import { Controller } from "stimulus";
import Rails from "@rails/ujs"
import Chart from 'chart.js/auto';

// Set of 15 default colors for the charts
const COLORS = [
    "#fd7f6f", // Warm Red
    "#7eb0d5", // Soft Blue
    "#b2e061", // Lime Green
    "#bd7ebe", // Soft Purple
    "#ffb55a", // Bright Orange
    "#ffee65", // Yellow
    "#beb9db", // Light Periwinkle
    "#fdcce5", // Pink
    "#8bd3c7", // Teal
    "#00a6ed", // Cyan
    "#f47e60", // Coral
    "#f7b32b", // Goldenrod
    "#53a548", // Forest Green
    "#8b2f97", // Dark Purple
    "#3255a4", // Royal Blue
    "#b55067", // Raspberry
    "#c1b2ab", // Taupe
    "#6f8fab", // Slate Blue
    "#e6a57e", // Peach
    "#d1d646"  // Olive Green
];

// const TOUCAN_COLORS = [
//     '#F26322', // Base Orange
//     '#E84304', // Darker Orange
//     '#FFA564', // Lighter Orange
//     '#FFD1B2', // Very Light Orange (almost peach)
//     '#C65D15', // Deep Orange
//     '#D49E0F', // Yellow-Orange
//     '#019aaa', // Partager (Teal Blue)
//     '#019aaa90', // Partager Hover (Translucent Teal)
//     '#2F4050', // Dark Slate
//     '#3B7A57', // Earthy Green (Complementary to Oranges)
//     '#7f3921', // Brownish-Orange
//     '#4f2217', // Dark Brown
//     '#A64B00', // Rustic Orange (Darker and muted)
// ];

export default class extends Controller {
    static values = {
        defaultAllFederationRecords: Boolean,
        itemCategoriesPath: String,
        activeTab: String
    }
    static targets = ["chartCanvas", "toggleSwitch"]

    connect() {
        this.charts = {};
        this.toggleState = this.defaultAllFederationRecordsValue;
        this.itemCategoriesPath = this.itemCategoriesPathValue;
        this.activeTab = this.activeTabValue;
        this.loadCharts();
    }

    loadCharts() {
        const order_tabs = ['purchase', 'distribution', 'purchase_need', 'distribution_offer']

        // This is a workaround as some charts in Order reports are still using two queries for rendering to maintain API compatibility for external users
        if (order_tabs.includes(this.activeTab)) {
            const url = this.constructUrlWithScope(this.itemCategoriesPath);
            // ItemCategories are fetched once for all the graphics in an Order statistics tabs
            Rails.ajax({
                type: "GET",
                url: url,
                dataType: 'json',
                success: (itemCategories) => {
                    const itemCategoriesColor = this.mapCategoryToColor(itemCategories);

                    // Filter the canvas elements to only those that are part of the active tab
                    const activeTabCanvas = this.chartCanvasTargets.filter(canvas => canvas.dataset.tabTarget === this.activeTab);

                    // Initialize charts for the filtered canvases
                    activeTabCanvas.forEach((canvas) => {
                        const canvasId = canvas.getAttribute('id');
                        const shouldUseItemCategories = canvas.dataset.itemCategoriesQuery === "true";
                        // This is a workaround as some charts in Order reports are still using two queries for rendering to maintain API compatibility for external users
                        this.initializeChart(canvas, canvasId, shouldUseItemCategories ? itemCategories : undefined, shouldUseItemCategories ? itemCategoriesColor : undefined);
                    });
                },
                error: (error) => {
                    console.error("Error fetching item categories:", error);
                }
            })
        } else {
            // Loops through each HTML canvas element in the Active Tab
            this.chartCanvasTargets.forEach((canvas) => {
                if (canvas.dataset.tabTarget === this.activeTab) {
                    const canvasId = canvas.getAttribute('id');
                    this.initializeChart(canvas, canvasId);
                }
            });
        }
    }

    // Reloads the charts if the User toggles the grouped descendants data
    toggle() {
        this.toggleState = !this.toggleState;
        this.loadCharts();
    }

    // Maps a color to each item category to ensure color consistency between the Pie charts and the Bar charts
    mapCategoryToColor(itemCategories) {
        let colorIndex = 0;
        const categoriesColors = {};

        itemCategories.forEach(category => {
            // The colors will be duplicated if the number of item categories is greater than the number of colors set in the COLORS array
            categoriesColors[category.id] = COLORS[colorIndex % COLORS.length];
            colorIndex++;
        });
        return categoriesColors;
    }

    // Constructs the URL based on the toggle state
    constructUrlWithScope(resourceUrl) {
        const url = new URL(resourceUrl, window.location.origin);
        if (this.toggleState) {
            url.searchParams.set('include_descendants', 'true');
        } else {
            url.searchParams.set('include_descendants', 'false');
        }
        return url.toString();
    }

    displayNoDataWarning(canvas) {
        this.clearNoDataWarning(canvas);

        // Build icon and warning message
        let warningMessage = document.createElement('div');
        warningMessage.className = 'text-muted mt-3 d-block ml-auto mr-auto text-center';
        let icon = document.createElement('i');
        icon.className = 'fa-light fa-magnifying-glass-chart fa-2x';
        warningMessage.appendChild(icon);
        warningMessage.appendChild(document.createElement('br'));
        warningMessage.appendChild(document.createTextNode(canvas.dataset.noDataMessage));
        canvas.parentNode.insertBefore(warningMessage, canvas.nextSibling);

        // Toggles the container height if data is present or not
        let parentElement = canvas.parentNode;
        if (parentElement.classList.contains('chart-container')) {
            parentElement.classList.add('no-data');
        }

        canvas.style.display = 'none';
    }

    clearNoDataWarning(canvas) {
        // Remove the warning message if it exists
        let warningMessage = canvas.nextElementSibling;
        if (warningMessage && warningMessage.textContent === canvas.dataset.noDataMessage) {
            warningMessage.remove();
        }

        // Toggles the container height if data is present or not
        let parentElement = canvas.parentNode;
        if (parentElement.classList.contains('chart-container')) {
            parentElement.classList.remove('no-data');
        }

        canvas.style.display = '';
    }


    initializeChart(canvas, canvasId, itemCategories = null, itemCategoriesColors = null) {
        let ctx = canvas.getContext('2d');

        // Destroy the existing chart instance if it exists
        if (this.charts[canvasId]) {
            this.charts[canvasId].destroy();
            delete this.charts[canvasId];
        }

        // Construct the URL based on the toggle state
        const url = this.constructUrlWithScope(canvas.dataset.resource);

        // Fetch the chart data
        Rails.ajax({
            type: "get",
            dataType: 'json',
            url: url,
            success: (response) => {
                if (response && response.length > 0) {
                    // Clear any no data warning if present.
                    this.clearNoDataWarning(canvas);

                    const chartType = canvas.dataset.chartType;
                    const maxItems = 10; // The layout breaks if there are too many items, mostly when "grouping" descendants.

                    let labels, datasets;
                    if (chartType === 'pie') {
                        // Combine data and labels into a single array of objects
                        const combined = response.map((data, index) => {
                            const category = itemCategories ? itemCategories.find(itemCategory => itemCategory.id === data[0]) : null;
                            return {
                                label: category ? category.name : data[0],
                                value: data[1],
                                color: itemCategories ? itemCategoriesColors[data[0]] : COLORS[index % COLORS.length],
                            };
                        });

                        // Sort the combined array based on the value
                        combined.sort((a, b) => b.value - a.value);

                        // Extract sorted data, labels, and colors
                        labels = combined.map(item => item.label);

                        const borderWidth = labels.length < maxItems ? 2 : 0.5;
                        const sortedData = combined.map(item => item.value);
                        const backgroundColors = combined.map(item => item.color);
                        datasets = [{
                            data: sortedData,
                            backgroundColor: backgroundColors,
                            borderWidth: borderWidth,
                        }];
                    } else if (chartType === 'bar' || chartType === 'horizontalBar') {
                        // If the chart is a horizontal bar chart, sort the data arrays in descending order
                        if (chartType === 'horizontalBar') {
                            // Step 1: Calculate total for each category
                            let categoryTotals = {};
                            response.forEach(dataset => {
                                dataset.data.forEach(([category, value]) => {
                                    categoryTotals[category] = (categoryTotals[category] || 0) + value;
                                });
                            });

                            // Step 2: Sort categories based on total
                            let sortedCategories = Object.entries(categoryTotals)
                                .sort((a, b) => b[1] - a[1])
                                .map(entry => entry[0]);

                            // Step 3: Remap each dataset
                            response.forEach(dataset => {
                                dataset.data.sort((a, b) => {
                                    let posA = sortedCategories.indexOf(a[0]);
                                    let posB = sortedCategories.indexOf(b[0]);
                                    return posA - posB;
                                });
                            });
                        }

                        // Use itemCategories if available for bar chart labels and background color
                        labels = itemCategories ? response[0].data.map(data => data[0]) : response[0].data.map(data => data[0]);
                        datasets = itemCategories ? response.map(dataset => {
                            return {
                                label: itemCategories.find(itemCategory => itemCategory.id === dataset.name)?.name,
                                data: dataset.data.map((data) => data[1]),
                                backgroundColor: itemCategoriesColors[dataset.name],
                            };
                        }) : response.map((dataset, index) => {
                            return {
                                label: dataset.name,
                                data: dataset.data.map((data) => data[1]),
                                backgroundColor: COLORS[index % COLORS.length],
                            };
                        });
                    }

                    // Base chart options
                    let baseChartOptions = {
                        plugins: {
                            legend: {
                                labels: {
                                    boxWidth: 10,
                                    boxHeight: 10,
                                    padding: 10,
                                }
                            },
                            autocolors: {
                                offset: 0
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    };

                    let chartOptions = { ...baseChartOptions };
                    // Customize options based on chartType
                    if (chartType === 'bar' || chartType === 'horizontalBar') {
                        chartOptions.plugins.legend.position = "bottom";
                        chartOptions.plugins.autocolors.mode = 'dataset';
                        chartOptions.scales = {
                            x: { stacked: true },
                            y: { stacked: true }
                        };
                        if (chartType === 'horizontalBar') {
                            chartOptions.indexAxis = 'y';
                        }
                    } else if (chartType === 'pie') {
                        chartOptions.plugins.legend.position = "right";
                        chartOptions.plugins.autocolors.mode = 'data';

                        chartOptions.plugins.legend.labels.generateLabels = (chart) => {
                            const labels = chart.data.labels;
                            const colors = chart.data.datasets[0].backgroundColor;

                            return labels.slice(0, maxItems).map((label, index) => {
                                return {
                                    text: label,
                                    fillStyle: colors[index],
                                    strokeStyle: 'transparent',
                                };
                            });
                        };
                    }

                    this.charts[canvasId] = new Chart(ctx, {
                        type: chartType === 'horizontalBar' ? 'bar' : chartType,
                        options: chartOptions,
                        data: {
                            labels,
                            datasets,
                        }
                    });

                } else {
                    this.displayNoDataWarning(canvas);
                }
            },
            error: (error) => {
                console.error("Error fetching chart data:", error);
            }
        });
    }
}
