import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    static targets = ["privatePageSelector", "privatePageIdsField"]

    connect() {
        this.sortableItemsDiv = this.element.querySelector('.sortable-items');
        if (this.sortableItemsDiv) {
            this.sortable = Sortable.create(this.sortableItemsDiv, {
                onSort: this.updateSortedIds.bind(this),
            });
        }
    }

    addPublication() {
        const selectedPageId = this.privatePageSelectorTarget.value;
        const selectedPageText = this.privatePageSelectorTarget.options[this.privatePageSelectorTarget.selectedIndex].text;

        if (selectedPageId) {
            // Create new HTML list item
            const newListElement = document.createElement('li');
            newListElement.className = 'list-group-item';
            newListElement.setAttribute('data-id', selectedPageId);
            newListElement.innerText = selectedPageText;

            // Create the delete link
            const deleteLink = document.createElement('a');
            deleteLink.className = 'text-danger pull-right';
            deleteLink.dataset.action = 'click->sortable#removePublication';
            const icon = document.createElement('i');
            icon.className = 'fa-regular fa-trash-can fa-lg ml-2';
            deleteLink.appendChild(icon);

            // Create a hidden checkbox input
            const checkbox = document.createElement('input');
            checkbox.type = 'checkbox';
            checkbox.name = 'campaign[private_page_ids][]';
            checkbox.value = selectedPageId;
            checkbox.checked = true;
            checkbox.style.display = 'none';

            // Append the delete link and checkbox to the list item
            newListElement.appendChild(checkbox);
            newListElement.appendChild(deleteLink);
            this.element.appendChild(newListElement);

            const sortableItemsDiv = this.element.querySelector('.sortable-items');
            if (sortableItemsDiv) {
                sortableItemsDiv.appendChild(newListElement);
            }

            // Clear the select field
            $(this.privatePageSelectorTarget).val(null).trigger('change');
        }
    }

    removePublication(event) {
        const item = event.target.closest('li');
        if (item) {
            const pageId = item.dataset.id;

            const checkbox = this.element.querySelector(`input[type='checkbox'][value='${pageId}']`);
            if (checkbox) {
                checkbox.parentNode.removeChild(checkbox);
            }

            item.parentNode.removeChild(item);
        }
    }

    updateSortedIds() {
        const sortedIds = Array.from(this.element.children).map(item => item.dataset.id)
    }
}
