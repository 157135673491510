import Rails from "@rails/ujs";
Rails.start()
window.jQuery = $;
window.$ = $;
require("turbolinks").start()
require("trix")
require("@rails/actiontext")
require("jquery")
require('./map/public')
require('./toucan_typeahead')

import "bootstrap/dist/js/bootstrap";
import "select2";
import Bloodhound from "./toucan_bloodhound";
import Chart from 'chart.js/auto';
import autocolors from 'chartjs-plugin-autocolors';

// Application specific code
import "controllers";

document.addEventListener("turbolinks:load", function() {
    $('.select2').select2({
        theme: "bootstrap4",
        // allowClear: true,
    });
    // See: https://github.com/stimulusjs/stimulus/issues/101
    $('.select2').on('select2:select', function() {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event)
    });

    // Collapse nearest div
    $('.collapse-link-section').on('click', function (e) {
        e.preventDefault();
        var $section;
        var sectionData = $(this).data("toggleSection");
        if (sectionData == undefined) {
            $section = $(this).parent().next('.section');
        } else {
            $section = $(sectionData);

        }
        $section.slideToggle(500);
    });

    // Widgets
    Chart.register(autocolors);
    const widget_weight_by_category = document.getElementById("weight_by_category");
    if ( widget_weight_by_category ) {
        var ctx_1 = widget_weight_by_category.getContext('2d');
        Rails.ajax({
            type: "get",
            dataType: 'json',
            url: ctx_1.canvas.dataset.itemCategoriesResource,
            success: function(item_categories) {
                Rails.ajax({
                    type: "get",
                    dataType: 'json',
                    url: ctx_1.canvas.dataset.resource,
                    success: function(response) {
                        if (response[0]) {
                            var myChart = new Chart(ctx_1, {
                                type: 'bar',
                                options: {
                                    plugins: {
                                        legend: {
                                            position: "bottom"
                                        },
                                        autocolors: {
                                            mode: 'dataset',
                                            offset: 0
                                        }
                                    },
                                    responsive: true,
                                    scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true
                                        }
                                    }
                                },
                                data: {
                                    labels: response[0].data.map(row => row[0]),
                                    datasets: response.map((row)=>{
                                        return {
                                            label: item_categories.find(item_category => item_category.id === row.name)?.name,
                                            data: row.data.map((f)=>{
                                                return f[1]
                                            })
                                        }
                                    })
                                },
                            });
                        }
                    },
                    error: function(response) {
                        const messages = response.messages;
                        if (messages) {
                            alert(messages.join(", "));
                        } else {
                            console.dir(response);
                        }
                    }
                })
            }
        })
    }

    const weight_by_category_by_quarter = document.getElementById("weight_by_category_by_quarter");
    if ( weight_by_category_by_quarter ) {
        var ctx_3 = weight_by_category_by_quarter.getContext('2d');
        Rails.ajax({
            type: "get",
            dataType: 'json',
            url: ctx_3.canvas.dataset.itemCategoriesResource,
            success: function(item_categories) {
                Rails.ajax({
                    type: "get",
                    dataType: 'json',
                    url: ctx_3.canvas.dataset.resource,
                    success: function(response) {
                        if (response[0]) {
                            var myChart = new Chart(ctx_3, {
                                type: 'bar',
                                options: {
                                    plugins: {
                                        legend: {
                                            position: "bottom"
                                        },
                                        autocolors: {
                                            mode: 'dataset',
                                            offset: 0
                                        }
                                    }
                                },
                                data: {
                                    labels: response[0].data.map(row => row[0]),
                                    datasets: response.map((row)=>{
                                        return {
                                            label: item_categories.find(item_category => item_category.id === row.name)?.name,
                                            data: row.data.map((f)=>{
                                                return f[1]
                                            })
                                        }
                                    })
                                },
                            });
                        }
                    },
                    error: function(response) {
                        const messages = response.messages;
                        if (messages) {
                            alert(messages.join(", "));
                        } else {
                            console.dir(response);
                        }
                    }
                })
            }
        })
    }

    const proportion_by_category_widget = document.getElementById("proportion_by_category");
    if ( proportion_by_category_widget ) {
        var ctx_2 = proportion_by_category_widget.getContext('2d');
        Rails.ajax({
            type: "get",
            dataType: 'json',
            url: ctx_2.canvas.dataset.itemCategoriesResource,
            success: function(item_categories) {
                Rails.ajax({
                    type: "get",
                    dataType: 'json',
                    url: ctx_2.canvas.dataset.resource,
                    success: function(response) {
                        if (response) {
                            var myChart = new Chart(ctx_2, {
                                type: 'pie',
                                options: {
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        autocolors: {
                                            mode: 'data',
                                            offset: 0
                                        }
                                    }
                                },
                                data: {
                                    labels: response.map((f)=>{
                                        return item_categories.find(item_category => item_category.id === f[0])?.name
                                    }),
                                    datasets: [{
                                        data: response.map(row => row[1])
                                    }]
                                },
                            });
                        }
                    },
                    error: function(response) {
                        const messages = response.messages;
                        if (messages) {
                            alert(messages.join(", "));
                        } else {
                            console.dir(response);
                        }
                    }
                })
            }
        })
    }
});
