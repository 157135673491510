import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['acceptCheckbox', 'acceptNetworkCheckbox', 'formSubmit'];

  toggleFormSubmit(event) {
    event.preventDefault();
    // If the checkbox is checked, enable the button
    if (this.hasAcceptCheckboxTarget) {
      if (this.hasAcceptNetworkCheckboxTarget) {
        this.formSubmitTarget.disabled = !(this.acceptNetworkCheckboxTarget.checked && this.acceptCheckboxTarget.checked);
      }
      else {
        this.formSubmitTarget.disabled = !this.acceptCheckboxTarget.checked;
      }
    }
  }
}
