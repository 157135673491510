import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source", "target" ];

    initialize() {
        this.hideTargets()
        this.showTarget(this.sourceTarget.value)
    }

    handleSelectChange() {
        this.hideTargets();
        this.showTarget(this.sourceTarget.value)
    }

    showTarget(context) {
        const selectedTarget = this.element.querySelector(`[data-context='${context}']`);
        if (selectedTarget) {
            selectedTarget.disabled = false;
            selectedTarget.style.display = "block";
        }
    }

    hideTargets() {
        this.targetTargets.forEach(element => {
            element.disabled = true;
            element.style.display = "none";
        })
    }
}
