import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['forceBucketEmpty', 'bucket', 'list', 'form', 'confirmationAlert'];

  initialize() {
    if (this.hasBucketTargetPresent) {
      const bucket = this.bucketTarget;
      const bucketValue = parseInt(bucket.value, 10);
      const submitButton = this.findSubmitButton();
      if (bucketValue > 0 && this.disableBtn()) {
        bucket.classList.add('bg-warning');
        if (submitButton) {
          submitButton.classList.remove('btn-primary');
          submitButton.classList.add('btn-outline-secondary');
          submitButton.disabled = this.disableBtn();
        }
      } else {
        if (this.hasConfirmationAlertTarget) {
          this.confirmationAlertTarget.classList.add('d-none');
        }
      }
    }
  }

  add(event) {
    const input = this.findQuantityInput(event);
    const newValue = this.calculate(input.value, '+');
    input.value = newValue;
  }

  remove(event) {
    const input = this.findQuantityInput(event);
    const newValue = this.calculate(input.value, '-');
    input.value = newValue;
  }

  calculate(oldValue, operator) {
    const bucket = this.bucketTarget;
    const bucketValue = parseInt(bucket.value, 10);
    const submitButton = this.findSubmitButton();
    let newValue = oldValue;

    if (operator == '+') {
      if (bucketValue > 0) {
        console.log('add');
        newValue = parseInt(oldValue, 10) + 1;
        bucket.value = bucketValue - 1;
      } else {
        console.log('bucket is empty :(');
      }
    } else {
      if (oldValue <= 0) {
        console.log('nothing to remove on that line :(');
      } else {
        console.log('remove');
        newValue = parseInt(oldValue, 10) - 1;
        bucket.value = bucketValue + 1;
      }
    }

    if (bucket.value > 0) {
      this.disableQuickFinalizedButtons();
    }

    if (bucket.value > 0 && this.disableBtn()) {
      bucket.classList.add('bg-warning');
      if (this.hasConfirmationAlertTarget) {
        this.confirmationAlertTarget.classList.remove('d-none');
      }
      if (submitButton) {
        submitButton.classList.remove('btn-primary');
        submitButton.classList.add('btn-outline-secondary');
        submitButton.disabled = this.disableBtn();
      }
    } else {
      bucket.classList.remove('bg-warning');
      if (this.hasConfirmationAlertTarget) {
        this.confirmationAlertTarget.classList.add('d-none');
      }
      if (submitButton) {
        submitButton.classList.remove('btn-outline-secondary');
        submitButton.classList.add('btn-primary');
        submitButton.disabled = false;
      }
    }

    return newValue;
  }

  findItem(id) {
    return this.formTarget.querySelector(`input[data-id="${id}"]`);
  }

  disableBtn() {
    return this.forceBucketEmptyTarget.value.toString() == 'true';
  }

  findQuantityInput(event) {
    return this.findItem(event.target.dataset.id);
  }

  findSubmitButton() {
    return this.formTarget.querySelector('input[type="submit"]');
  }

  findQuickFinalizedButtons() {
    return this.formTarget.querySelectorAll('.quick-finalized');
  }

  disableQuickFinalizedButtons() {
    let elements = this.findQuickFinalizedButtons();
    // loop through all the elements
    elements.forEach(element => {
      element.classList.add('disabled');
    });
  }

  enableQuickFinalizedButtons() {
    let elements = this.findQuickFinalizedButtons();
    // loop through all the elements
    elements.forEach(element => {
      element.classList.remove('disabled');
    });
  }
}
