import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [ "facet", "addressFrom", "addressTo", "program", "middleman", "firstItemSelect", "grouped", "organization", "seller", "buyer", "organizationContainer", "firstItemContainer" ]
    connect() {
        if (this.hasFacetTarget) {
            if (this.facetTarget.dataset.quickAdd == "true") {
                this.hideFirstItemContainer()
                const self = this;
                $(this.sellerTarget).on('select2:clear', function(event, callback) {
                    self.hideFirstItemContainer()
                }).bind(this)
                $("#toggle_new_item .form-check-input").on('click', function(event, callback) {
                    if (event.target) {
                        if (event.target.checked) {
                            self.facetTarget.value = "new_variant";
                        } else {
                            self.facetTarget.value = "existing_variant";
                        }
                    }
                }).bind(this)
            } else {
                this.facetTarget.value = "none";
            }
        }
    }

    setDefaults() {
        if (this.hasOrganizationContainerTarget && this.hasGroupedTarget) {
            if (this.groupedTarget.selectedOptions[0].value === "true") {
                this.organizationContainerTarget.style.display = "none";
                this.sellerTarget.disabled = true;
            } else {
                this.organizationContainerTarget.style.display = "block";
                this.sellerTarget.disabled = false;
            }
        }
    }

    setFirstItemContainer() {
        const sellerGid = this.sellerTarget.selectedOptions[0].value
        if (sellerGid != "") {
            this.getSeller(sellerGid);
        } else {
            this.hideFirstItemContainer()
        }
    }

    setFirstItemContainerForBuyer() {
        const buyerGid = this.buyerTarget.selectedOptions[0].value
        if (buyerGid != "") {
            this.getBuyer(buyerGid);
        } else {
            this.hideFirstItemContainer()
        }
    }

    showFirstItemContainer() {
        if (this.facetTarget.dataset.quickAdd == "true") {
            this.firstItemContainerTarget.removeAttribute("hidden");
        }
    }

    hideFirstItemContainer() {
        // this.facetTarget.value = "none";
        if (this.facetTarget.dataset.quickAdd == "true") {
            if (this.facetTarget.value == "none") {
                this.firstItemContainerTarget.setAttribute("hidden", true);
            } else {
                this.firstItemContainerTarget.removeAttribute("hidden");
                this.setFirstItemContainer();
            }
        }
    }

    showOnlyNewItemContainer() {
        // TODO: use `target`
        if (this.facetTarget.dataset.quickAdd == "true") {
            const existingItemContainer = document.getElementById('existing_variant');
            const newItemContainer = document.getElementById('new_item');
            const cb = document.getElementById('toggle_new_item');
            cb.style.display = 'none';
            newItemContainer.style.display = 'block';
            existingItemContainer.style.display = 'none';
        }
    }

    resetNewItemContainer() {
        // TODO: use `target`
        if (this.facetTarget.dataset.quickAdd == "true") {
            const existingItemContainer = document.getElementById('existing_variant');
            const newItemContainer = document.getElementById('new_item');
            const cb = document.getElementById('toggle_new_item');
            cb.style.display = 'block';
            newItemContainer.style.display = 'none';
            existingItemContainer.style.display = 'block';
        }
    }

    getSeller(gid) {
        const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
        const self = this;
        const encodedGid = encodeURIComponent(gid);
        Rails.ajax({
            type: "GET",
            url: `/${accountId}/api/v1/subjects/${encodedGid}.json`,
            success: (data) => {
                // Set default from address
                self.setAddressFrom(gid);
                // Set default middleman
                self.setMiddleman(data.middleman_id);
                // Set default program
                $(self.programTarget).val(data.program_id);
                $(self.programTarget).trigger('change');

                if (data.supplier_items_count == 0) {
                    self.facetTarget.value = "new_variant";
                    self.showFirstItemContainer();
                    self.showOnlyNewItemContainer();
                } else {
                    self.facetTarget.value = "existing_variant";
                    self.getSupplierItems(gid);
                    self.showFirstItemContainer();
                    self.resetNewItemContainer();
                }
            },
        });
    }

    getBuyer(gid) {
        const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
        const self = this;
        const encodedGid = encodeURIComponent(gid);
        Rails.ajax({
            type: "GET",
            url: `/${accountId}/api/v1/subjects/${encodedGid}.json`,
            success: (data) => {
                // Set default from address
                self.setAddress(gid);
                // Set default middleman
                self.setMiddleman(data.middleman_id);
                // Set default program
                $(self.programTarget).val(data.program_id);
                $(self.programTarget).trigger('change');

                // if (data.supplier_items_count == 0) {
                //     self.facetTarget.value = "new_variant";
                //     self.showFirstItemContainer();
                //     self.showOnlyNewItemContainer();
                // } else {
                //     self.facetTarget.value = "existing_variant";
                //     self.getSupplierItems(gid);
                //     self.showFirstItemContainer();
                //     self.resetNewItemContainer();
                // }
            },
        });
    }

    getSupplierItems(gid) {
        const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
        const self = this;
        const encodedGid = encodeURIComponent(gid);
        Rails.ajax({
            type: "GET",
            url: `/${accountId}/api/v1/subjects/${encodedGid}/supplier_items.json`,
            success: (data) => {
                if (self.hasFirstItemSelectTarget) {
                    $(self.firstItemSelectTarget).select2({
                        data: data
                    })
                }
            }
        });
    }

    setAddressFrom(gid) {
        const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
        const self = this;
        const encodedGid = encodeURIComponent(gid);
        Rails.ajax({
            type: "GET",
            url: `/${accountId}/api/v1/subjects/${encodedGid}/addresses.json`,
            success: (data) => {
                if (data.length > 0) {
                    $(self.addressFromTarget).select2({
                        data: data
                    })
                    $(self.addressFromTarget).val(data[0].id);
                    $(self.addressFromTarget).trigger('change');
                } else {
                    $(self.addressFromTarget).select2({
                        data: null
                    })
                    $(self.addressFromTarget).val(null).trigger('change');
                }
            }
        });
    }

    setAddress(gid) {
        const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
        const self = this;
        const encodedGid = encodeURIComponent(gid);
        Rails.ajax({
            type: "GET",
            url: `/${accountId}/api/v1/subjects/${encodedGid}/addresses.json`,
            success: (data) => {
                if (data.length > 0) {
                    $(self.addressToTarget).select2({
                        data: data
                    })
                    $(self.addressToTarget).val(data[0].id);
                    $(self.addressToTarget).trigger('change');
                } else {
                    $(self.addressToTarget).select2({
                        data: null
                    })
                    $(self.addressToTarget).val(null).trigger('change');
                }
            }
        });
    }

    setMiddleman(id) {
        if (id) {
            const accountId = document.getElementsByTagName("body")[0].dataset.accountId;
            const self = this;
            Rails.ajax({
                type: "GET",
                url: `/${accountId}/api/v1/organizations.json?q[id_eq]=${id}`,
                success: (data) => {
                    if (data.length > 0) {
                        $(self.middlemanTarget).select2({
                            data: data
                        })
                        $(self.middlemanTarget).val(id);
                        $(self.middlemanTarget).trigger('change');
                    } else {
                        $(self.middlemanTarget).select2({
                            data: null
                        })
                        $(self.middlemanTarget).val(null).trigger('change');
                    }
                }
            });
        } else {
            return;
        }
    }
}
