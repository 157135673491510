import { Controller } from 'stimulus';

// add the JS
import SlimSelect from 'slim-select';
// add the CSS
import 'slim-select/dist/slimselect.css';

// Connects to data-controller="slim-select"
export default class extends Controller {
  connect() {
    this.setup();
  }

  disconnect() {
    this.cleanup();
  }

  search(searchTerm, callback) {
    let _this = this;
    fetch(this.searchUrl + searchTerm, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        // Upon successful fetch send data to callback function.
        // Be sure to send data back in the proper format.
        // Refer to the method setData for examples of proper format.
        callback(_this.formatData(json));
      })
      .catch(function (error) {
        // If any errors happened send false back through the callback
        console.dir(error);
        callback(false);
      });
  }

  formatData(data) {
    return data;
  }

  afterChange(newVal) {
    return newVal;
  }

  setup() {
    let _this = this;
    this.cleanup();
    const options = {
      select: this.element,
      settings: {
        hideSelectedOption: true,
        showSearch: this.showSearch,
        searchingText: `${I18n.searching}`,
        searchText: `${I18n.no_result}`,
        searchPlaceholder: `${I18n.search}`,
        searchHighlight: true,
        placeholderText: '',
        allowDeselect: this.allowDeselect,
        openPosition: 'auto', // 'auto', 'up' or 'down'
      },
    };
    const defaultEvents = {
      afterChange: this.afterChange,
      beforeOpen: () => {
        document.getElementById('scroller').scrollIntoView({ behavior: 'smooth' })
      },
    };
    let searchEvents = {};

    if (this.searchUrl) {
      searchEvents = {
        search: search => {
          return new Promise((resolve, reject) => {
            if (search.length < 3) {
              return reject(`${I18n.minimum_search}`);
            }

            _this.rateLimitedSearch(search, resolve);
          });
        },
      };
    }

    options.events = { ...defaultEvents, ...searchEvents };

    this.slimSelect = new SlimSelect(options);
  }

  rateLimitedSearch(searchTerm, callback, timeout = 200) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.search(searchTerm, callback);
    }, timeout);
  }

  cleanup() {
    if (!this.slimSelect) return;
    this.slimSelect.destroy();
    delete this.slimSelect;
  }

  // Configuration options via data attributes
  get dependentElements() {
    return this.element.dataset.dependentElements || [];
  }

  // This will allow you to deselect a value on a single/multiple select dropdown.
  // Be sure to have an empty option data placeholder so slim select has an empty string value to select.
  get allowDeselect() {
    return this.element.dataset.allowDeselect === 'true';
  }

  // A boolean value that will decide whether or not to show the search.
  get showSearch() {
    return this.element.dataset.showSearch === 'true';
  }

  // What is the JSON API endpoint to use to fetch data from
  get searchUrl() {
    return this.element.dataset.searchUrl;
  }
}
