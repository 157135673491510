import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["personRadioOption", "organizationRadioOption", "personCustomFormAttributes", "organizationCustomFormAttributes"]

    togglePersonRadioOptions() {
        // Manage the display custom attributes
        if (event.target.checked) {
            this.personCustomFormAttributesTarget.style.display = "block";
        } else {
            this.personCustomFormAttributesTarget.style.display = "none";
        }

        // disable/enable exisitng people radio buttons
        this.personRadioOptionTargets.forEach((el, i) => {
            if (event.target.checked) {
                el.disabled = true;
            } else {
                el.disabled = false;
            }
            el.checked = false;
        })
    }

    toggleOrganizationRadioOptions() {
        // Manage the display custom attributes
        if (event.target.checked) {
            this.organizationCustomFormAttributesTarget.style.display = "block";
        } else {
            this.organizationCustomFormAttributesTarget.style.display = "none";
        }

        // disable/enable exisitng people radio buttons
        this.organizationRadioOptionTargets.forEach((el, i) => {
            if (event.target.checked) {
                el.disabled = true;

            } else {
                el.disabled = false;
            }
            el.checked = false;
        })
    }
}
