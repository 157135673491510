import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["summary", "fullBody", "summaryBtn", "fullBodyBtn"]

    hideFullBody(e) {
        this.fullBodyTarget.style.display = "none";
        this.summaryTarget.style.display = "block";

        this.fullBodyBtnTarget.style.display = "none";
        this.summaryBtnTarget.style.display = "block";
    }

    showFullBody(e) {
        this.fullBodyTarget.style.display = "block";
        this.summaryTarget.style.display = "none";

        this.fullBodyBtnTarget.style.display = "block";
        this.summaryBtnTarget.style.display = "none";
    }
}
