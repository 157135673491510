import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "facet", "newVariantForm" ]

    initialize() {
        if (this.facetTarget.value == "") {
            this.setDefaultFacet()
        }
        if (this.facetTarget.value == "existing_variant") {
            $('#facet-tabs a[href="#existing_item"]').tab('show');
        }
        if (this.facetTarget.value == "new_variant") {
            $('#facet-tabs a[href="#existing_item"]').tab('show');
            this.newVariantFormTarget.removeAttribute("hidden");
        }
        if (this.facetTarget.value == "new_item") {
            $('#facet-tabs a[href="#new_item"]').tab('show');
        }
    }

    setFacet(event) {
        const newFacet = event.target.dataset.facetParam;
        this.facetTarget.value = newFacet;
        if (newFacet == "new_variant") {
            event.preventDefault()
            this.toggleNewVariantForm()
        }
    }

    toggleNewVariantForm() {
        const isHidden = this.newVariantFormTarget.hasAttribute("hidden");
        // reset the existing variant select
        // FIXME: not the perfect solution :(
        // Example: if we change the item select, the variant is set to something again :(
        // Fix server side, probably...
        document.getElementById("new_inventory_form_product_variant_id").selectedIndex = 0;
        // Show form
        if (isHidden) {
            this.newVariantFormTarget.removeAttribute("hidden");
        // Hide form
        } else {
            // Reset to default facet
            this.setDefaultFacet();
            this.newVariantFormTarget.setAttribute("hidden", true);
        }
    }

    setDefaultFacet() {
        this.facetTarget.value = "existing_variant";
    }
}
