import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select" ]

  connect() {
    if (this.hasSelectTarget && this.selectTarget.value === '') {
      if (this.selectTarget.classList.contains('select2')) {
        $(this.selectTarget).select2('open')
      } else {
        $(this.selectTarget).focus()
      }
    }
  }
}
