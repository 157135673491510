import { Controller } from "stimulus"

import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [ "submitBtn", "tbody", "itemVariantForm", "price" ]

    handleSuccess(event) {
        const [_data, _status, xhr] = event.detail
        this.tbodyTarget.innerHTML = xhr.response
        this.tbodyTarget.classList.add("bg-highlighted");
    }

    handleError(event) {
        const [_data, _status, xhr] = event.detail
        this.tbodyTarget.innerHTML = xhr.response
    }

    handleProductVariantToggle(event) {
        const radioBtn = event.target;
        if (parseInt(radioBtn.value) > 0) {
            this.priceTarget.value = radioBtn.dataset.price;
            this.itemVariantFormTarget.hidden = false;
            this.submitBtnTarget.hidden = false;
        } else {
            this.itemVariantFormTarget.hidden = true;
            this.submitBtnTarget.hidden = true;
        }
    }
}
