import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
    static targets = ['checkboxAll', 'checkbox', 'form']

    initialize () {
        super.initialize()
        if (this.hasFormTarget) {
            this.bulk = this.bulk.bind(this)
            this.bulk()
        }
    }

    connect() {
        super.connect()
        this.checkboxAllTarget.addEventListener('change', this.bulk)
        this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', this.bulk))
    }

    bulk () {
        if (this.checked.length > 0) {
            $(this.formTarget).slideDown();
            $(this.formTarget).find('.select2').select2({ theme: 'bootstrap4' });
        } else {
            $(this.formTarget).slideUp();
        }
    }
}
