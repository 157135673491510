import { Controller } from "stimulus"
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import popper from 'cytoscape-popper';

export default class extends Controller {
    static targets = ["widget"]

    connect() {
        const graphEl = this.widgetTarget;
        const elements = JSON.parse(this.widgetTarget.dataset.elements)
        cytoscape.use( dagre );
        cytoscape.use( popper );
        var cy = cytoscape({
            container: graphEl,
            boxSelectionEnabled: false,
            autounselectify: true,
            userZoomingEnabled: false,
            layout: {
                name: "dagre",
                // padding: 10,
                // idealEdgeLength: 100,
                // nodeOverlap: 20,
                // refresh: 20,
                // fit: true,
                // randomize: false,
                // componentSpacing: 100,
                // nodeRepulsion: 400000,
                // edgeElasticity: 100,
                // nestingFactor: 5,
                // gravity: 80,
                // numIter: 1000,
                // initialTemp: 200,
                // coolingFactor: 0.95,
                // minTemp: 1.0
            },
            style: [
                {
                    selector: 'node',
                    style: {
                        'content': ''
                    }
                },

                {
                    selector: 'edge',
                    style: {
                        'curve-style': 'bezier',
                        'target-arrow-shape': 'triangle'
                    }
                }
            ],
            elements: elements,
        });
        cy.on('click', 'node', function(event) {
            var node = event.target;
            let popper1 = node.popper({
                content: () => {
                    let div = document.getElementById('tooltip');

                    div.innerHTML = node.data().mode + " <strong>" + node.data().name + "</strong> - " + node.data().quantity + "<br>" + node.data().subject + "<br>" + "<em>" + node.data().account + "</em>";

                    return div;
                },
                popper: {
                    placement: 'right-start',
                } // my popper options here
            });
        });
    }
}
