import L from "leaflet/dist/leaflet";
require("leaflet.markercluster"); // Adds L.markerClusterGroup onto L

function onEachFeature(feature, layer) {
    var marker = layer;
    var properties = feature.properties;
    if (properties) {
        var popupContent = '<div class="marker-popup">' +
                            '<h3>' + properties.name + '</h3>' +
                            '<h4 style="color: ' + properties.color+ '"><i class="fa fa-users"></i> ' + properties.opening_status + '</h4>' +
                            '<p class="overflow-auto p-0 m-b-4" style="max-height: 200px;">' + properties.message + '</p>' +
                            '<div><strong>' + properties.audiences_label + ':</strong> <span>' + properties.audiences + '</span></div>' +
                            '<div><strong>' + properties.services_label + ':</strong> <span>' + properties.services + '</span></div>' +
                            '<hr>' +
                            '<address>' +
                            '<h4>' + properties.address + '</h4>' +
                            '<i class="fa fa-phone"></i> ' + properties.phone_number +
                            '<br>' +
                            '<i class="fa fa-globe"></i> ' + properties.website_link +
                            '</address>' +
                           '</div>';
        marker.bindPopup(popupContent, {closeButton: false, minWidth: 300});
    }
}

document.addEventListener("turbolinks:load", function() {
    var mapElement = document.querySelector("#public_map");
    if (mapElement) {
        var placesUrl = mapElement.dataset.placesUrl;
        var mapboxToken = mapElement.dataset.mapboxToken;
        var waypoints = mapElement.dataset.waypoints;
        var places = $.ajax({
            url: placesUrl,
            dataType: "json",
            error: function(xhr) {
                alert(xhr.statusText)
            }
        })
        $.when(places).done(function() {
            var placeMap = L.map(mapElement);
            placeMap.scrollWheelZoom.disable();
            placeMap.once("focus", function() { placeMap.scrollWheelZoom.enable(); });
            L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                maxZoom: 18,
                id: 'mapbox/streets-v11',
                accessToken: mapboxToken
            }).addTo(placeMap);

            var markerClusters = L.markerClusterGroup({
            });

            var kyPlaces = L.geoJSON(places.responseJSON, {
                pointToLayer: function(feature, latlng) {
                    return L.circleMarker(latlng, {
                        radius: feature.properties.radius,
                        fillColor: feature.properties.color,
                        color: "#000",
                        weight: 1,
                        opacity: 0.8,
                        fillOpacity: feature.properties.opacity
                    })
                },
                onEachFeature: onEachFeature
            })

            markerClusters.addLayer(kyPlaces);
            placeMap.addLayer(markerClusters);

            placeMap.fitBounds(markerClusters.getBounds());
        });
    }
});
