const localSessionKey = "jumbotron.viewed"

import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        this.name = this.element.getAttribute("data-name");
        this.viewed = this.element.getAttribute("data-viewed");
        if ( this.viewed == "true" ) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        this.element.style.display = "block";
    }

    hide() {
        this.element.style.display = "none";
        this.postJson();
    }

    postJson() {
        self = this;
        fetch("/user_settings", {
            method: "post",
            body: JSON.stringify({context: "jumbotron", value: true, name: self.name + '_viewed'}),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin'
        }).then(function(response) {
            return response.json();
        });
    }
}
